import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { translate } from 'react-i18next';

const styles = {
  NotPublishedSite: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: ({ background }) => (background),
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    color: '#fff'
  },
  info:{
    textAlign: 'center',
    width: '95%',
  },
  logoWrapper:{
    width: '260px',
  },
  logo:{
    width: '100%',
  }
};

class NotPublishedSite extends Component {

  render() {
    const { classes, t, logo } = this.props;
    return (
      <div className={classes.NotPublishedSite}>
        <h2 className={classes.info}>{t('not_published_site')}</h2>
        <div className={classes.logoWrapper}>
          <img className={classes.logo} src={logo} alt='logo'></img>
        </div>
      </div>
    );
  }
}

export default injectSheet(styles)(translate('Trans')(NotPublishedSite));

import { COMPOSER_SETTINGS_SUCCESS } from "composer-blocks/lib/actions/site";
import { getEntityFromResponse } from 'composer-blocks/lib/helpers/api';

const globalSettings = (state = {faviconUrl: null, loginBackgroundColor: {r: 85, g: 40, b: 85, a: 1}, sitePassword: null }, action) => {
  switch (action.type) {
    case COMPOSER_SETTINGS_SUCCESS:
      return getEntityFromResponse(action, 'globalSettings') || state;
    default:
      return state;
  }
};

export default globalSettings;

import { COMPOSER_SETTINGS_SUCCESS } from "composer-blocks/lib/actions/site";
import { getEntityFromResponse } from 'composer-blocks/lib/helpers/api';

const byId = (state = {}, action) => {
  switch (action.type) {
    case COMPOSER_SETTINGS_SUCCESS: {
      return getEntityFromResponse(action, 'contents') || state;
    }
    default:
      return state;
  }
};

export default byId;

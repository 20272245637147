export default {
  en: {
    not_published_site: "We're sorry, the website you're looking for is not published",
    log_in: "Log in",
    password: "Password",
    empty_password: "Password can not be empty",
    incorrect_password: "Incorrect password.",
    event_management_software: 'Event Management Software',
    age_gate_continue: "Continue",
    day: "Day",
    month: "Month",
    year: "Year",
  },
  es: {
    not_published_site: "Lo sentimos, el sitio que estás buscando no se encuentra publicado",
    log_in: "Ingresar",
    password: "Contraseña",
    empty_password: "La contraseña no puede estar vacía",
    incorrect_password: "Contraseña inválida",
    event_management_software: 'Software para Gestión de Eventos',
    age_gate_continue: "Continuar",
    day: "Día",
    month: "Mes",
    year: "Año",
  },
  fr: {
    not_published_site: "Nous sommes désolés, le site que vous recherchez n'est pas publié.",
    log_in: "S'enregistrer",
    password: "Mot de passe",
    empty_password: "Le mot de passe ne peut pas être vide",
    incorrect_password: "Mot de passe incorrect",
    event_management_software: "Logiciel de Gestion d'Evénements",
    age_gate_continue: "Continuer",
    day: "Jour",
    month: "Mois",
    year: "Année",
  },
  de: {
    not_published_site: "Es tut uns leid, die von Ihnen gesuchte Website ist nicht veröffentlicht.",
    log_in: "Einloggen",
    password: "Passwort",
    empty_password: "Das Passwort darf nicht leer sein",
    incorrect_password: "Falsches Passwort",
    event_management_software: 'Software voor evenementenbeheer',
    age_gate_continue: "Fortsetzung",
    day: "Tag",
    month: "Monat",
    year: "Jahr",
  }
}

import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import clsx from 'clsx';
import Modal from '@material-ui/core/Modal';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import EventtiaLogo from '../assets/eventtiaLogo.png';
import { defaultGlobalFont } from '../helpers/fonts';

const getContrastText = (color) => {
  const average = (color.r + color.g + color.b) / 3;
  return (average >= 128) ? '#707070' : 'white';
};

const rgbaFromColor = (color) => `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;

const gradient = (color, transitionPoint = 6) => `linear-gradient(to right, transparent, ${color} ${transitionPoint}%, ${color} ${100 - transitionPoint}%, transparent)`;

const styles = {
  backdrop: {
    backgroundColor: ({ backdropColor: bdc }) => bdc ? rgbaFromColor(bdc): '#FFF',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 30,
  },
  dialog: {
    background: 'none',
    fontFamily: defaultGlobalFont,
  },
  dialogContent: {
    padding: '10px 15px 25px 15px',
    background: ({ popupColor: pc }) => pc ? gradient(rgbaFromColor(pc)): '#FFF',
  },
  imgLogoWrapper: {
    textAlign: 'center',
    margin: '8px 40px 15px 40px',
  },
  imgLogo: {
    height: 100,
    width: 'auto',
    maxWidth: '100%',
    objectFit: 'contain',
  },
  text: {
    fontSize: 16,
    fontFamily: 'inherit',
    color: ({ popupColor: pc }) => pc ? getContrastText(pc) : '#707070',
    textAlign: 'center',
    margin: '0 20px',
  },
  gridItem: {
    margin: 10,
    display: 'flex',
    justifyContent: 'center',
  },
  textField: {
    maxWidth: 150,
    '& input': {
      fontSize: 16,
      textAlign: 'center',
      fontFamily: defaultGlobalFont,
      color: ({ popupColor: pc }) => pc ? getContrastText(pc) : '#707070',
    },
    '& fieldset': {
      zIndex: -10,
      borderColor: ({ popupColor: pc }) => pc ? getContrastText(pc) : '#707070',
      backgroundColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: ({ popupColor: pc }) => [pc ? getContrastText(pc) : '#707070', '!important'],
    },
  },
  select: {
    '& fieldset': {
      zIndex: -10,
      backgroundColor: 'white',
    },
  },
  unselected: {
    color: '#AAA',
  },
  buttonWrapper: {
    textAlign: 'center',
    marginTop: 8,
  },
  button: {
    borderRadius: 5,
    width: 150,
    textTransform: 'capitalize',
    backgroundColor: ({ buttonColor: bc }) => bc ? rgbaFromColor(bc): '#22D2B9',
    color: 'white',
    fontWeight: 'bold',
    fontSize: 14,
    fontFamily: 'inherit',
    '&:hover': {
      backgroundColor: ({ buttonColor: bc }) => bc ? rgbaFromColor(bc): '#22D2B9',
    },
    '&$disabled': {
      '& span': {
        color: 'white',
      },
      backgroundColor: '#C7C7C7',
    },
  },
  disabled: {
  },
  footer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    padding: 8,
    textAlign: 'center',
    fontFamily: defaultGlobalFont,
    backgroundColor: ({ popupColor: pc }) => pc ? rgbaFromColor(pc) : 'white',
    color: ({ popupColor: pc }) => pc ? getContrastText(pc) : '#707070',
  },
  '@media (min-width: 400px)': {
    dialogContent: {
      padding: '30px 45px 75px 45px',
      background: ({ popupColor: pc }) => pc ? gradient(rgbaFromColor(pc), 10): '#FFF',
    },
    imgLogoWrapper: {
      margin: '8px 20px 30px 20px',
    },
    text: {
      fontSize: 22,
      margin: '28px 0',
    },
    textField: {
      '& input': {
        fontSize: 22,
      },
    },
    button: {
      width: 175,
      fontSize: 16,
    },
    buttonWrapper: {
      marginTop: 16,
    },
  },
};

class AgeGate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonEnabled: false,
      day: '',
      month: '',
      year: '',
      isValidated: false,
      showDropdowns: false,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { year, month, day } = this.state;
    if (prevState.year !== year || prevState.month !== month || prevState.day !== day) {
      if (!this.isNumeric(year)) {
        this.setState({ showDropdowns: false });
        if (prevState.buttonEnabled) this.setState({ buttonEnabled: false })
      }
      else {
        const yearNumber = parseInt(year, 10);
        const pivotDate = new Date();
        const currentYear = pivotDate.getFullYear();
        const upperBoundYear = currentYear - this.props.minAge;
        if (yearNumber === upperBoundYear) this.setState({ showDropdowns: true })
        if (yearNumber > upperBoundYear) this.setState({ showDropdowns: false })
        if (yearNumber < upperBoundYear && yearNumber >= 1900) {
          this.setState({ buttonEnabled: true, showDropdowns: false })
        }
        else {
          if (!this.isNumeric(month) || !this.isNumeric(day)) {
            if (prevState.buttonEnabled) this.setState({ buttonEnabled: false })
          }
          else {
            const monthNumber = parseInt(month - 1, 10);
            const dayNumber = parseInt(day, 10);
            if (yearNumber < 1900) this.setState({ buttonEnabled: false })
            else {
              const birthDate = new Date(yearNumber, monthNumber, dayNumber);
              pivotDate.setFullYear(upperBoundYear);
              if (pivotDate.getTime() > birthDate.getTime()) this.setState({ buttonEnabled: true })
              else if (prevState.buttonEnabled) this.setState({ buttonEnabled: false })
            }
          }
        }
      }
    }
  }

  isNumeric = (value) => /^\d+$/.test(value)
  getQueryParam = (name) => {
     name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
     let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
     let results = regex.exec(window.location.search);
     return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  fillFields = () => (this.state.day !== '' && this.state.month !== '' && this.state.year !== '')
  onChange = (name) => (event) => this.setState({ [name]: event.target.value })
  getAgeGateStamp = () => `${window.location.pathname.split('/')[2]}-agegate`

  ageNotValidated = () => (!localStorage.getItem(this.getAgeGateStamp()) || localStorage.getItem(this.getAgeGateStamp()) !== 'v_a') && !this.state.isValidated
  commitAge = () => {
    localStorage.setItem(this.getAgeGateStamp(), 'v_a');
    this.setState({isValidated: true});
  }

  render() {
    const { classes, t, backdropColor, popupColor, buttonColor, description, errorMsg, logo, eventUri } = this.props;
    const skipAgeGateParam = this.getQueryParam('skip-age-gate');
    const openAgeGateModal = this.ageNotValidated();

    const footerHack = ['lateliermartellsg'].includes(eventUri);

    return (
      openAgeGateModal && !(!!skipAgeGateParam && skipAgeGateParam === 'true') && (
        <Modal
          className={classes.modal}
          BackdropProps={{ className: classes.backdrop }}
          open={openAgeGateModal}
        >
            <Dialog
              open={openAgeGateModal}
              maxWidth="sm"
              classes={{ paper: classes.dialog }}
            >
              <div className={classes.dialogContent}>
                <div className={classes.imgLogoWrapper}>
                  <img alt='modal-logo' src={logo || EventtiaLogo} className={classes.imgLogo}/>
                </div>
                {description && (
                  <p className={classes.text}>{description}</p>
                )}
                <Grid container direction="row">
                  <Grid item xs className={classes.gridItem}>
                    <TextField
                      placeholder={t('year')}
                      variant="outlined"
                      margin="dense"
                      className={classes.textField}
                      value={this.state.year}
                      onChange={this.onChange('year')}
                    />
                  </Grid>
                  {this.state.showDropdowns && (
                    <React.Fragment>
                      <Grid item xs className={classes.gridItem}>
                        <TextField
                          select
                          variant="outlined"
                          margin="dense"
                          value={this.state.month}
                          onChange={this.onChange('month')}
                          SelectProps={{
                            displayEmpty: true,
                            className: clsx(classes.select, !this.state.month && classes.unselected),
                          }}
                          fullWidth
                        >
                          <MenuItem value="">
                            {t('month')}
                          </MenuItem>
                          {Array.from(Array(12), (e,i)=>i+1).map((monthNumber) => (
                             <MenuItem
                                key={monthNumber}
                                value={monthNumber}
                              >
                               {monthNumber}
                             </MenuItem>
                           ))}
                        </TextField>
                      </Grid>
                      <Grid item xs className={classes.gridItem}>
                        <TextField
                          select
                          variant="outlined"
                          margin="dense"
                          value={this.state.day}
                          onChange={this.onChange('day')}
                          SelectProps={{
                            displayEmpty: true,
                            className: clsx(classes.select, !this.state.day && classes.unselected),
                          }}
                          fullWidth
                        >
                          <MenuItem value="">
                            {t('day')}
                          </MenuItem>
                          {Array.from(Array(31), (e,i)=>i+1).map((dayNumber) => (
                             <MenuItem
                                key={dayNumber}
                                value={dayNumber}
                              >
                               {dayNumber}
                             </MenuItem>
                           ))}
                        </TextField>
                      </Grid>
                    </React.Fragment>
                  )}
                </Grid>
                {errorMsg && !this.state.buttonEnabled && this.fillFields() && (
                  <p className={classes.text}>{errorMsg}</p>
                )}
                <div className={classes.buttonWrapper}>
                  <Button
                    onClick={this.commitAge}
                    disabled={!this.state.buttonEnabled}
                    classes={{root: classes.button, disabled: classes.disabled}}
                  >
                    {t('age_gate_continue')}
                  </Button>
                </div>
              </div>
              {footerHack && (
                <div className={classes.footer}>
                  {`Please do not share with anyone under ${this.props.minAge}. Drink responsibly`}
                </div>
              )}
            </Dialog>
        </Modal>
      )
    );
  }
}

const mapStateToProps = ({ site, globalSettings }) => ({
  globalFontId: globalSettings.globalFontId,
  accountFonts: site.entities.account_fonts,
});

export default compose(
  connect(mapStateToProps),
  injectSheet(styles),
  translate('Trans')
)(AgeGate);

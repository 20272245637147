import { COMPOSER_SETTINGS_SUCCESS } from "composer-blocks/lib/actions/site";
import { getEntityFromResponse } from 'composer-blocks/lib/helpers/api';

const initialState = [
];

const pageOrder = (state = initialState, action) => {
  switch (action.type) {
    case COMPOSER_SETTINGS_SUCCESS: {
      let newPageOrder = getEntityFromResponse(action, 'pageOrder');
      if (!newPageOrder) {
        const pages = getEntityFromResponse(action, 'pages') || {};
        newPageOrder = Object.keys(pages);
      }
      return newPageOrder || state;
    }

    default:
      return state;
  }
};

export default pageOrder;

import { COMPOSER_SETTINGS_SUCCESS } from "composer-blocks/lib/actions/site";
import { getEntityFromResponse } from 'composer-blocks/lib/helpers/api';

const initialState = {
  blocks: [],
};
const navbars = (state = initialState, action) => {
  switch (action.type) {
    case COMPOSER_SETTINGS_SUCCESS: {
      return getEntityFromResponse(action, 'navbars') || state;
    }
    default:
      return state;
  }
};

export default navbars;

import Trans from '../locales/Trans';
import Misc from 'composer-blocks/lib/locales/Misc.js';

export default {
  en: {
    Trans: { ...Trans.en },
    Misc: { ...Misc.en },
  },
  es: {
    Trans: { ...Trans.es },
    Misc: { ...Misc.es },
  },
  fr: {
    Trans: { ...Trans.fr },
    Misc: { ...Misc.fr },
  },
  de: {
    Trans: { ...Trans.de },
    Misc: { ...Misc.de },
  },
}

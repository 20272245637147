import { fetchComposerSettings, setCurrentPage } from "composer-blocks/lib/actions/site";
import { getCurrentPageId } from 'composer-blocks/lib/helpers/pages';
import i18n from '../helpers/i18n';

export const fetchComposerSettingsIfNeeded = (event_uri, page) => (dispatch, getState) => {
  const state = getState();
  const events = state.site.entities.events;

  let w_location = window.location;
  let locale;
  locale = w_location.pathname.split('/')[1];
  if(locale.length > 2){
    let index = w_location.href.search(event_uri);
    locale = w_location.href.slice(index-3, index-1);
  }
  if(!locale)
    locale = 'en';
  if (!!events) return;

  dispatch(fetchComposerSettings(event_uri, {'website_lang':locale , live:true })).then((action) => {
    const composerSettings = action.response.entities.composer_settings;


    if(!!composerSettings[Object.keys(composerSettings)[0]])
      i18n.changeLanguage(composerSettings[Object.keys(composerSettings)[0]].language)

    const site = composerSettings[Object.keys(composerSettings)[0]].site;
    if (!!site) {
      const pageId = getCurrentPageId(page, site.pages, site.pageOrder);
      return dispatch(setCurrentPage(pageId));
    }
  })
}

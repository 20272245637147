import { COMPOSER_SETTINGS_SUCCESS } from "composer-blocks/lib/actions/site";

const initialState = {};

const otherData = (state = initialState, action) => {
  switch (action.type) {
    case COMPOSER_SETTINGS_SUCCESS: {
      const { site, ...other } = Object.values(action.response.entities.composer_settings)[0];
      return other;
    }
    default:
      return state;
  }
};

export default otherData;

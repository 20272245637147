import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import injectSheet from 'react-jss';
import Block from 'composer-blocks/lib/containers/Block';
import Content from 'composer-blocks/lib/containers/Content';
import responsiveModes, { MOBILE } from 'composer-blocks/lib/constants/ResponsiveModes';
import { setCurrentPage, setResponsiveMode } from 'composer-blocks/lib/actions/site';
import { getCurrentPageId } from 'composer-blocks/lib/helpers/pages';
import scrollToHash from '../helpers/scrollToHash';
import PoweredByFooter from '../components/PoweredByFooter';
import AgeGate from '../components/AgeGate';
import '../assets/quill-css-v2-1.css';

const styles = {
  container: {
  }
};

let optimizedResize = (function() {
  let callbacks = [],
    running = false;

  // fired on resize event
  function resize() {
    if (!running) {
      running = true;
      if (window.requestAnimationFrame) {
        window.requestAnimationFrame(runCallbacks);
      } else {
        setTimeout(runCallbacks, 66);
      }
    }
  }

  // run the actual callbacks
  function runCallbacks() {
    callbacks.forEach(function(callback) {
      callback();
    });
    running = false;
  }

  // adds callback to loop
  function addCallback(callback) {
    if (callback) {
        callbacks.push(callback);
    }
  }

  return {
    // public method to add additional callback
    add: function(callback) {
      if (!callbacks.length) {
        window.addEventListener('resize', resize);
      }
      addCallback(callback);
    },
    clear: function() {
      callbacks = [];
    }
  }
}());

class Viewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trackingGA: false,
      trackingGA4: false,
      trackingGTM: false,
    };
  }

  componentDidMount() {
    this.monitorResize();
    optimizedResize.add(this.monitorResize);
    this.checkEventTrackers();
    scrollToHash(this.props.location.hash)
  }

  componentWillUnmount() {
    optimizedResize.clear();
  }

  monitorResize = () => {
    const width = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth;
    const currentResponsiveMode = this.props.responsiveMode;
    let expectedResponsiveMode = responsiveModes[MOBILE].name;
    for (let responsiveMode of Object.keys(responsiveModes).map(k => responsiveModes[k])) {
      if (width > responsiveMode.minWidth && width <= responsiveMode.maxWidth) {
        expectedResponsiveMode = responsiveMode.name;
      }
    }
    if (expectedResponsiveMode !== currentResponsiveMode) {
      this.props.setResponsiveMode(expectedResponsiveMode);
    }
  }

  checkEventTrackers = () => {
    const { event } = this.props;
    if (!!event) {
      this.setState({
        trackingGA: !!event.googleAnalyticsTrackingCode,
        trackingGA4: !!event.googleAnalytics4TrackingCode,
        trackingGTM: !!event.googleTagManagerTrackingCode
      });
    }
  }

  trackPageView = () => {
    if (!!window.ga) {
      window.ga('send', 'pageview');
      if (this.state.trackingGA) window.ga('customer.send', 'pageview');
    }
    if (!!window.dataLayer) {
      window.dataLayer.push({
        'event': 'Pageview',
        'url': window.location.pathname + window.location.search + window.location.hash
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.page !== this.props.match.params.page) {
      if(!this.props.location.hash){
        window.scrollTo(0, 0);
      }
      this.props.setCurrentPage(getCurrentPageId(nextProps.match.params.page, nextProps.pages, nextProps.pageOrder));
      this.trackPageView();
    }
  }

  renderBlock = (isNavbarBlock,isFooterBlock) => ((blockId, index) => {
    const { siteMaxWidth } = this.props;
    return (
      <Block
        key={blockId}
        blockId={blockId}
        index={index}
        editable={false}
        ContentClass={Content}
        siteMaxWidth={siteMaxWidth || '100%'}
        bodyBlock={!isNavbarBlock && !isFooterBlock}
      />
    );
  });

  renderNavbarBlock = this.renderBlock(true,false);
  renderMainBlock = this.renderBlock(false,false);
  renderFooterBlock = this.renderBlock(false,true);

  render() {
    const { classes, navbarBlockIds, blockIds, footerBlockIds, hideFooter, hideNavbar, event, responsiveMode, ageGate } = this.props;
    const { whiteLabel } = event;

    return (
      <div className={classes.container}>
        {!hideNavbar && navbarBlockIds.map(this.renderNavbarBlock)}
        {ageGate.enabled && <AgeGate {...ageGate} eventUri={event.eventUri} />}
        {blockIds.map(this.renderMainBlock)}
        {!hideFooter && footerBlockIds.map(this.renderFooterBlock)}
        {!whiteLabel && <PoweredByFooter responsiveMode={responsiveMode}/>}
      </div>
    );
  }

}

const mapStateToProps = ({ site, pages, pageOrder, navbars, footers, globalSettings }) => ({
  pages,
  pageOrder,
  event: site.entities.events[Object.keys(site.entities.events)[0]],
  navbarBlockIds: navbars.blocks ? navbars.blocks : [],
  footerBlockIds: footers.blocks ? footers.blocks : [],
  blockIds: (site.currentPageId && pages[site.currentPageId].blocks) ? pages[site.currentPageId].blocks : [],
  hideNavbar: site.currentPageId && pages[site.currentPageId].hideNavbar,
  hideFooter: site.currentPageId && pages[site.currentPageId].hideFooter,
  responsiveMode: site.responsiveMode,
  siteMaxWidth: globalSettings.siteMaxWidth,
  ageGate: globalSettings.ageGate || {},
});

const mapDispatchToProps = {
  setCurrentPage,
  setResponsiveMode
}

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectSheet(styles)
);

export default enhance(Viewer);

import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import injectSheet from 'react-jss';
import { translate } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';


const styles = {
  container: {
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    background: ({ backgroundColor }) => backgroundColor,
    alignItems: 'center'
  },
  loginOuterBox:{
    width: '250px',
    backgroundColor: '#f5f5f5',
    padding: '1rem',
    height: '18rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 0px 22px 0px rgba(0,0,0,0.75)'
  },
  loginInnerBox: {
    textAlign: 'center',
    height: '12.5rem',
  },
  formControl: {
    margin: 'auto',
    width: '200px',
    display: 'flex',
    minHeight: '4.2rem'
  },
  Button:{
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    borderRadius: '0',
    margin: '1rem 0',
    '&:hover':{
      backgroundColor: ({ backgroundColor }) => backgroundColor,
    }
  }
};


class LiveLogin extends Component {

  constructor(props){
    super(props);
    this.state = {
      password: '',
      errorMsg: null
    };
  }

  onChangeTextInput = (name) => (event) => this.setState({ [name]: event.target.value })

  handleSubmit = () => {
    const { t } = this.props;
    const encodedPass = btoa(this.state.password);
    if(encodedPass === ""){ this.setState({ errorMsg: t('empty_password') }); return; }
    if(encodedPass !== this.props.sitePassword){ this.setState({ errorMsg: t('incorrect_password') }); return; }
    sessionStorage.setItem('l_p', encodedPass);
    this.props.showSite();
  }


  renderLoginBox = () =>  {
    const { siteMaxWidth, classes, event, t } = this.props;
    return (
      <div className={classes.loginOuterBox}>
        <div className={classes.loginInnerBox}>
          <h3>{event.name.toUpperCase()}</h3>
          <FormControl className={classes.formControl} error aria-describedby="component-error-text">
            <TextField
              onChange={this.onChangeTextInput('password')}
              value={this.state.password}
              type="password"
              label={t('password')}
            />
          {!!this.state.errorMsg && (
              <FormHelperText>{this.state.errorMsg}</FormHelperText>
          )}
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            className={classes.Button}
            onClick = {this.handleSubmit}
          >
          {t('log_in').toUpperCase()}
          </Button>
        </div>
      </div>
    );
  };


  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        {this.renderLoginBox()}
      </div>
    );
  }
}

const mapStateToProps = ({ site }) => ({
  event: site.entities.events[Object.keys(site.entities.events)[0]],
});

const enhance = compose(
  connect(mapStateToProps),
  injectSheet(styles),
  translate('Trans')
);

export default enhance(LiveLogin);
